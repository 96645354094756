optoviewModule.controller('ProfileController', ['$scope', '$http', 'toastr', 'helpers', 'User', function ($scope, $http, toastr, helpers, User) {
    $scope.helpers = helpers;
    $scope.hidePasswords = true;
    $scope.showReset = true;
    $scope.showDisable = true;
    $scope.formErrors = {};

    $scope.initializeCc = function (scope) {
        $scope.ccScope = scope;
    };

    $scope.disabled = function (disabled) {
        $scope.user.status = disabled ? 'disabled' : 'enabled';
        $scope.processForm();
    };

    $scope.updateVisibility = function (visible) {
        $scope.user.always_hide_in_searches = visible;
        $scope.processForm();
    };

    $scope.processForm = function () {

        $scope.user.exam_capabilities = [];
        if ($scope.user.isDRCapable) {
            $scope.user.exam_capabilities.push('DigitalRetinopathy');
        }
        if ($scope.user.isDPCapable) {
            $scope.user.exam_capabilities.push('DigitalPathology');
        }

        User.update($scope.user)
            .then(function (response) {
                $scope.formErrors = [];
                $scope.updateUser(response.data);
                $scope.showSuccessMessage('Your profile was updated successfully');
            })
            .catch((err) => {
                if (err.data.errors) {
                    $scope.formErrors = err.data.errors; 
                }
                $scope.showErrorMessage('Errors saving your profile. Please see highlighted field(s).');
            });
    };

    $scope.resetPassword = function () {
        $http({
            method: 'POST',
            url: '/reset-password',
            data: { email: user.email }
        })
            .then((response) => {
                if (response.status === 200) {
                    $scope.showSuccessMessage('Please check the email address associated with this account to continue password reset.');
                } else {
                    $scope.showErrorMessage('Something went wrong. If this persists please contact support@io.care.');
                }
            })
            .catch(() => {
                $scope.showErrorMessage('Something went wrong. If this persists please contact support@io.care.');
            });
    };

    $scope.submitCreditCard = function () {
        $scope.ccScope.processCreditCard(function (response) {
            $http({
                method: 'POST',
                url: routes.user.updateCard,
                data: {
                    "token": response.id
                }
            })
                .then(function success(successResponse) {
                    $scope.user.card_brand = successResponse.data.data.card_brand;
                    $scope.user.card_last_four = successResponse.data.data.card_last_four;
                    $scope.userCCErrors = {};
                    $scope.ccScope.clearCardInfo();
                    toastr.success('Your credit card was successfully updated.');
                }, function error(errorResponse) {
                    toastr.error(errorResponse.data.errors[0], {
                        timeOut: 0
                    });
                });
        });
    };

    $scope.cancelSubscription = function () {
        if (confirm("Are you sure you want to cancel your subscription?") == true) {
            $http({
                method: 'POST',
                url: routes.user.cancelSubscription
            })
                .then(function success(response) {
                    $scope.updateUser(response.data.data);
                    $scope.showSuccessMessage('Your subscription was successfully cancelled.');
                }, function error(errorResponse) {
                    toastr.error(errorResponse.data.errors[0], {
                        timeOut: 0
                    });
                });
        }
    }

    $scope.isOphthalmologist = function () {
        if (!$scope.user.exam_capabilities) {
            return false;
        }
        return $scope.user.role == "Ophthalmologist";
    }

    $scope.isCapable = function (cap) {
        if (!$scope.user.exam_capabilities) {
            return false;
        }
        return $scope.user.exam_capabilities.includes(cap);
    }

    $scope.updateUser = function (user) {
        if (user) {
            $scope.user = user;
        }
        $scope.user.isDRCapable = $scope.isCapable("DigitalRetinopathy");
        $scope.user.isDPCapable = $scope.isCapable('DigitalPathology');
    }

    $scope.updateUser();
}]);