optoviewModule.controller('CreateAdminController', function($scope, Users, $uibModalInstance, toastr) {
    $scope.user = {};
    $scope.hidePractice = true;
    $scope.hideCertification = true;
    $scope.hidePasswords = false;
    $scope.title = 'Create Admin';
    $scope.templateBody = '/templates/user/info.html';

    $scope.submit = function () {
        Users.createAdmin($scope.user).then(function (user) {
            toastr.success(user.data.name + ' admin account created.');
            $uibModalInstance.close();
        }, function(response) {
            $scope.formErrors = response.data;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});