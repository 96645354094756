optoviewModule.config(function ($httpProvider, toastrConfig, $stateProvider, $urlRouterProvider) {
    $httpProvider.interceptors.push('errorInterceptor');

    $urlRouterProvider.otherwise(function ($injector, $location) {
        var state = $injector.get('$state');
        state.go('404');
        return $location.path();
    });
    $urlRouterProvider.when('', function () {
        if (user.status == 'disabled') {
            return '/profile';
        }
        if (typeof user != 'undefined') {
            if (user.role == 'Admin') {
                return '/users';
            }
            return '/pendingExams';
        }
    });
    $stateProvider
        .state('404', {
            template: '<div>Page not found</div>'
        })
        .state('pending-exams', {
            url: '/pendingExams',
            templateUrl: '/templates/exam/pendingExams.html',
            controller: 'ExamListController',
            resolve: {
                examListConfig: function () {
                    return {
                        defaultSort: { exam_date: 'asc' },
                        examStatuses: ['Pending', 'Saved'],
                        dateColumn: 'created_at'
                    }
                }
            }
        })
        .state('pending-exam', {
            url: '/pendingExam/:examId',
            controller: 'ExamController',
            templateUrl: '/templates/exam/exam.html'
        })
        .state('dp-pending-exam', {
            url: '/pendingExam/dp/:examId',
            controller: 'DpExamController',
            templateUrl: '/templates/exam/dp-exam.html'
        })
        .state('reviewed-exams', {
            url: '/reviewedExams',
            templateUrl: '/templates/exam/reviewedExams.html',
            controller: 'ExamListController',
            resolve: {
                examListConfig: function () {
                    return {
                        defaultSort: { review_date: 'desc' },
                        examStatuses: ['Reviewed', 'Opened'],
                        dateColumn: 'review_date'
                    }
                }
            }
        })
        .state('reviewed-exam', {
            url: '/reviewedExam/:examId',
            controller: 'ExamController',
            templateUrl: '/templates/exam/exam.html'
        })
        .state('dp-reviewed-exam', {
            url: '/reviewedExam/dp/:examId',
            controller: 'DpExamController',
            templateUrl: '/templates/exam/dp-exam.html'
        })
        .state('patients', {
            url: '/patients',
            templateUrl: '/templates/patients/list.html',
            controller: 'PatientsListController',
            resolve: {
                patientsListConfig: function () {
                    return {
                        defaultSort: { last_name: 'asc' }
                    }
                }
            }
        })
        .state('patient', {
            url: '/patients/:patientId',
            controller: 'PatientController',
            templateUrl: '/templates/patients/view.html',
            resolve: {
                subState: function () {
                    return 'view'
                },
            },
        })
        .state('patient-add', { 
            url: '/patients/new',
            controller: 'PatientController',
            templateUrl: '/templates/patients/form.html',
            resolve: {
                subState: function () {
                    return 'add'
                },
            },
        })
        .state('patient-edit', {
            url: '/patients/:patientId/edit',
            controller: 'PatientController',
            templateUrl: '/templates/patients/form.html',
            resolve: {
                subState: function () {
                    return 'edit'
                },
            }
        })
        .state('patients-import', {
            url: '/patients/import',
            controller: 'PatientsImportController',
            templateUrl: '/templates/patients/importForm.html',
        })
        .state('users-list', {
            url: '/users',
            templateUrl: '/templates/user/list.html'
        })
        .state('metrics', {
            url: '/metrics',
            controller: 'MetricsController',
            templateUrl: '/templates/admin/metrics.html'
        })
        .state('reassignment-requests', {
            url: '/reassignment-requests',
            controller: 'ReassignmentListController',
            templateUrl: '/templates/admin/reassignment-requests.html',
            resolve: {
                reassignmentListConfig: function () {
                    return {
                        defaultSort: { request_updated: 'desc' },
                        dateColumn: 'updated_at'
                    }
                }
            }
        })
        .state('profile', {
            url: '/profile',
            templateUrl: 'templates/user/profile.html'
        })
        .state('invite', {
            url: '/invite',
            templateUrl: 'templates/invite/index.html',
        })
        .state('terms-of-service', {
            url: '/terms-of-service',
            templateUrl: 'templates/layout/terms-of-service.html'
        })
        .state('privacy-policy', {
            url: '/privacy-policy',
            templateUrl: 'templates/layout/privacy-policy.html'
        })
        .state('baa', {
            url: '/baa',
            templateUrl: 'templates/layout/baa.html'
        });
}).run(function ($rootScope, currentUser) {
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        // console.log("State change from " + fromState.name + " to " + toState.name);
        exemptRoutes = ['terms-of-service', 'privacy-policy', 'baa'];
        //If user doesn't have access to state, prevent from loading.
        if (exemptRoutes.indexOf(toState.name) == -1 && !currentUser.hasAccess(toState.name)) {
            event.preventDefault();
        }
    });
});