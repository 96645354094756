optoviewModule.controller(
  'PatientsListController',
  function (
    $http,
    Patients,
    $scope,
    Users,
    toastr,
    currentUser,
    easyTable,
    helpers,
    patientsListConfig
  ) {
    $scope.currentUser = currentUser;

    $scope.refresh = function () {
      $scope.patientsTable.table.reload();
    };

    $scope.patientsTable = easyTable.create(Patients, {
      data: function () {
        return {
          search: $scope.search
        };
      },
      defaultSort: patientsListConfig.defaultSort
    });

    $scope.displayDueForExam = (last_exam_date) => {
      if (last_exam_date === null) return true;

      return new Date(last_exam_date) <= new Date(Date.now() - 28512e6);
    };

    $scope.displayEmptyLastExam = (last_exam_date) => {
      return last_exam_date === null;
    };
  }
);
