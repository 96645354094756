
ExamTypesData = function (ExamTypes) {
    const CACHE_KEY = "EXAM_TYPES";
    var that = this;

    that.updateExamTypesCache = function () {
        ExamTypes.get().then(function (data) {
            sessionStorage.setItem(CACHE_KEY, JSON.stringify(data.data));
        });
    };

    that.get = function () {
        try {
            return JSON.parse(sessionStorage.getItem(CACHE_KEY));
        } catch (error) {
            return {};
        }
    };

    that.init = function () {
        if (!sessionStorage.getItem(CACHE_KEY) || sessionStorage.getItem(CACHE_KEY) == 'undefined') {
            that.updateExamTypesCache();
        } else {
            try {
                JSON.parse(sessionStorage.getItem(CACHE_KEY));
            } catch (error) {
                that.updateExamTypesCache();
            }
        }
    };
}
angular.module('examTypes', [])
    .factory('examTypes', function (ExamTypes) {
        var examTypesData = new ExamTypesData(ExamTypes);
        examTypesData.init();
        return examTypesData;
    });