optoviewModule.controller('ReassignmentListController', function ($http, $scope, Exams, toastr, RequestReassignment, currentUser, easyTable, helpers, reassignmentListConfig, $uibModal) {
    $scope.requests = {};
    $scope.currentUser = currentUser;
    $scope.showPromoCode = false;

    $scope.fromDate = {
        opened: false,
        date: ''
    };

    $scope.toDate = {
        opened: false,
        date: ''
    };

    $scope.requestsTable = easyTable.create(RequestReassignment, {
        data: function () {
            return {
                dateColumn: reassignmentListConfig.dateColumn,
                dateFrom: $scope.fromDate.date ? helpers.formatDT($scope.fromDate.date, 'YYYY-MM-DD 00:00:00') : '',
                dateTo: $scope.toDate.date ? helpers.formatDT($scope.toDate.date, 'YYYY-MM-DD 24:00:00') : '',
                search: $scope.search
            }
        },
        defaultSort: reassignmentListConfig.defaultSort
    });

    $scope.update = function (request_id, exam_id, status) {
        return RequestReassignment.getRequestInfo(request_id).then( function (response) {
            var user_uuid = response.user_uuid;
            if (status == 'Approved') {
                $scope.assignExam(exam_id, user_uuid);
            }
            RequestReassignment.update(request_id, status).then(function () {
                toastr.success('Request Successfully ', status);
                $scope.requestsTable.table.reload();
            });
        });
    };

    $scope.assignExam = function (examId, user_id) {
        return Exams.assign(examId, user_id).then(function () {
            toastr.success('Exam assigned to ' + user.name);
            return true;
        }, function () {
            return 'Error updating.';
        });
    };

    $scope.refresh = function () {
        $scope.requestsTable.table.reload();
    };

    $scope.showReassignmentRequestModal = function (row) {
        $uibModal.open({
            animation: true,
            templateUrl: '/templates/exam/reassignmentRequest.html',
            controller: 'ReassignmentRequestController',
            resolve: {
                row: function () {
                    return row;
                },
            }
        });
    }

});