optoviewModule.controller('ExamListController', function ($http, Exams, $scope, Users, toastr, currentUser, easyTable, helpers, pendingExamCount, examListConfig, examTypes, $uibModal) {
    $scope.exams = {};
    $scope.patientName = '';
    $scope.currentUser = currentUser;
    $scope.examTypes = examTypes.get();
    $scope.showPromoCode = false;

    $scope.examTypesFilter = [
        {
            'name': 'All',
            'value': ''
        }
    ];

    Object.keys($scope.examTypes).forEach(examTypeDef => {
        var exTypeDef = $scope.examTypes[examTypeDef];
        var exType = {
            'name': exTypeDef.display_name,
            'value': examTypeDef
        }
        $scope.examTypesFilter.push(exType);
    });

    $scope.examType = '';

    $scope.fromDate = {
        opened: false,
        date: ''
    };

    $scope.toDate = {
        opened: false,
        date: ''
    };

    $scope.setShowPromoCode = function (value) {
        if ($scope.currentUser.role == 'Admin') {
            $scope.showPromoCode = value;
        }
    };

    $scope.selectExamType = function (type) {
        $scope.examType = type;
        $scope.refresh();
    }

    pendingExamCount.countChanged = function () {
        $scope.refresh();
    };

    $scope.refresh = function () {
        $scope.examTable.table.reload();
    };

    $scope.examTable = easyTable.create(Exams, {
        data: function () {
            return {
                status: examListConfig.examStatuses,
                dateColumn: examListConfig.dateColumn,
                dateFrom: $scope.fromDate.date ? helpers.formatDT($scope.fromDate.date, 'YYYY-MM-DD 00:00:00') : '',
                dateTo: $scope.toDate.date ? helpers.formatDT($scope.toDate.date, 'YYYY-MM-DD 24:00:00') : '',
                type: $scope.examType,
                search: $scope.search
            }
        },
        defaultSort: examListConfig.defaultSort
    });

    //Retrieve all specialists for reassignment request
    $scope.users = Users.where({
        meta: {
            pagination: {
                page: 1,
                count: 99999
            }
        },
        data: {
            user_role: 'Ophthalmologist',
            user_status: 'enabled'
        }
    }).then(function (result) {
        $scope.users = result.data;
    });

    $scope.decline = function (id) {
        Exams.decline(id).then(function () {
            $scope.examTable.table.reload();
        })
    };

    $scope.assignExam = function (examId, user) {
        return Exams.assign(examId, user.id).then(function () {
            toastr.success('Exam assigned to ' + user.name);
            return true;
        }, function () {
            return 'Error updating.';
        });
    };

    $scope.showReassignmentRequestModal = function (exam, users) {
        $scope.setExamType(exam);
        $uibModal.open({
            animation: true,
            templateUrl: '/templates/layout/modal.html',
            controller: 'ReassignmentRequestController',
            resolve: {
                exam: function () {
                    return exam;
                },
                users: function () {
                    return users;
                }
            }
        }).result.then(function () {
            $scope.formErrors = [];
            toastr.success('Reassignment request submitted successfully.')
        });
    }

    $scope.setExamType = function (exam) {
        $scope.examType = exam.type;
    }

    $scope.userCanReviewExam = function(spec) {
        return (spec.exam_capabilities.includes($scope.examType));
    }

    $scope.showWarningLabel = function (received_date) {
        return new Date(received_date) < new Date(Date.now() - 12096e5);
    }
});