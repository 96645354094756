angular.module('currentUser', [])
    .factory('currentUser', function () {
        //user is a variable defined globally in blade, (could be done through ajax)
        user = (typeof user == 'undefined') ? {} : angular.copy(user);

        if (user.status == 'disabled') {
            user.permissions = {
                Admin: ['profile', 'invite'],
                Ophthalmologist: ['profile', 'invite'],
                Physician: ['profile', 'invite']
            };
        } else {
            user.permissions = {
                Admin: [
                    'pending-exams',
                    'reviewed-exams',
                    'users-list',
                    'profile',
                    'metrics',
                    'invite',
                    'reassignment-requests'
                ],
                Ophthalmologist: [
                    'pending-exams',
                    'pending-exam',
                    'dp-pending-exam',
                    'reviewed-exams',
                    'reviewed-exam',
                    'dp-reviewed-exam',
                    'profile',
                    'invite'
                ],
                Physician: [
                    'pending-exams',
                    'reviewed-exams',
                    'reviewed-exam',
                    'dp-reviewed-exam',
                    'profile',
                    'invite',
                    'patients',
                    'patient',
                    'patient-edit',
                    'patient-add',
                    'patients-import'
                ]
            };
        }

        user.hasAccess = function (action) {
            return (user.permissions[user.role].indexOf(action) != -1);
        };

        user.hasSubscription = function() {
            if (!user.needs_subscription) {
                return true;
            }

            return user.has_monthly_subscription;
        }

        return user;
    });