optoviewModule.controller('DpExamController', ['$scope', '$http', '$stateParams', 'pendingExamCount', 'helpers', 'videoService', 'toastr', 'examTypes',
    function ($scope, $http, $stateParams, pendingExamCount, helpers, videoService, toastr, examTypes) {

        $scope.helpers = helpers;
        $scope.videoService = videoService.instance();
        $scope.currentExamId = $stateParams.examId;
        $scope.selectedExam = {};
        $scope.selectedVideo = null;
        $scope.pastExams = {};
        $scope.pendingExamCount = pendingExamCount;
        $scope.errors = [];
        $scope.examTypes = examTypes.get();
        $scope.personas = $scope.examTypes.DigitalPathology.personas;
        $scope.previousExam = {};
        $scope.nextExam = {};
        $scope.previousDisabled = false;
        $scope.nextDisabled = false;
        $scope.dates = ['date_time_of_collection', 'date_time_of_slide_creation', 'date_time_reported'];
        $scope.cpt_codes = ['', '92227', '92228', '92250'];

        $scope.$on("$destroy", function () {
            $scope.videoService.destroy();
            $("#left-nav").removeClass("in");
        });

        $scope.editable = function () {
            return $scope.selectedExam.status == 'Pending' || $scope.selectedExam.status == 'Saved'
        };

        $scope.setSelectedExam = function (exam) {
            $scope.selectedExam = exam;

            $scope.getPatientExams($scope.selectedExam.patient.data.id);

            $scope.selectedExam.images = [];

            $scope.selectedExam.patient.data.date_of_birth = helpers.formatDT($scope.selectedExam.patient.data.date_of_birth);

            for (i = 0; i < $scope.selectedExam.videos.data.length; i++) {
                if ($scope.selectedExam.videos.data[i].images) {
                    for (var i1 = 0; i1 < $scope.selectedExam.videos.data[i].images.data.length; i1++) {
                        $scope.selectedExam.images.push($scope.selectedExam.videos.data[i].images.data[i1]);
                    }
                }
            }

            $scope.dates.forEach(attr => {
                if ($scope.selectedExam[attr] && $scope.selectedExam[attr].length > 0) {
                    $scope.selectedExam[attr] = helpers.toDateObject($scope.selectedExam[attr]);
                }
            });

            if (!$scope.selectedExam.cpt_code) {
                $scope.selectedExam.cpt_code = '';
            }

        };

        $scope.getExam = function (examId) {
            $http({
                method: 'POST',
                url: '/' + api_prefix + '/getExam/' + examId
            }).then(function (response) {
                $scope.setSelectedExam(response.data.data);
                $scope.videoService.init();
                // console.log($scope.selectedExam);
                if ($scope.selectedExam.videos.data.length > 0) {
                    $scope.videoService.selectVideo($scope.selectedExam.videos.data[0], false);
                }
                $scope.getPrevious();
                $scope.getNext();
            });
        };

        $scope.getPrevious = function () {
            $http({
                method: 'POST',
                url: '/getPreviousExam/',
                data: { "examId": $scope.selectedExam.id }
            }).then(function (response) {
                if (response.data) {
                    $scope.previousExam = response.data;
                    $scope.previousDisabled = false;
                } else {
                    $scope.previousDisabled = true;
                }
            })
        }

        $scope.getNext = function () {
            $http({
                method: 'POST',
                url: '/getNextExam/',
                data: { "examId": $scope.selectedExam.id }
            }).then(function (response) {
                if (response.data) {
                    $scope.nextExam = response.data;
                    $scope.nextDisabled = false;
                } else {
                    $scope.nextDisabled = true;
                }
            })
        }

        $scope.getPatientExams = function (patientId) {
            $http({
                method: 'POST',
                url: '/' + api_prefix + '/getUserExams',
                data: {
                    patient_id: patientId,
                    status: ['Pending', 'Saved', 'Reviewed', 'Opened']
                }
            }).then(function (response) {
                $scope.pastExams = response.data.data;
            });
        };

        $scope.submitExam = function (exam, status) {
            $http({
                method: 'POST',
                url: '/exam/submit',
                data: {
                    id: exam.id,
                    findings: exam.findings,
                    rationale: exam.rationale,
                    followup: exam.followup,
                    followed_up: exam.followed_up,
                    status: status,
                    urgent: exam.urgent,
                    images: exam.addedImages,
                    tissue_extraction_source: exam.tissue_extraction_source,
                    procedure_for_extraction: exam.procedure_for_extraction,
                    date_time_of_collection: helpers.dateToDateString(exam.date_time_of_collection),
                    date_time_of_slide_creation: helpers.dateToDateString(exam.date_time_of_slide_creation),
                    date_time_reported: helpers.dateToDateString(exam.date_time_reported),
                    eml_accession_number: exam.eml_accession_number,
                    submitting_physician: exam.submitting_physician,
                    icd10_code: exam.icd10_code,
                    cpt_code: exam.cpt_code,
                }
            }).then(function (response) {
                $scope.errors = [];
                toastr.success('Exam has been ' + response.data.data.status);
                $scope.setSelectedExam(response.data.data);
            }, function (errorResponse) {
                $scope.errors = errorResponse.data.errors;
            });
        };

        $scope.goToTimestamp = function (exam, image) {
            var video = false;
            exam.videos.data.some(function (vid) {
                if (vid.id == image.video_id) {
                    video = vid;
                    return true;
                }
            });
            if (!video) {
                console.warn("Unable to find video this image was extracted from!");
                return;
            }
            if ($scope.videoService.selectedVideo.side != video.side) {
                $scope.selectEye(video.side);
            }
            $scope.videoService.goToTimestamp(exam, video, image);
        };

        $scope.getExam($scope.currentExamId);
    }]);