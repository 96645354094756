optoviewModule.controller('PatientsImportController',
    function ($scope, Patients, $stateParams, helpers, toastr, $location) {

        /**
         * The helpers lib
         */
        $scope.helpers = helpers;

        $scope.dzOptions = {
            url: '/patients/import',
            params: {
                _token: CSRF_TOKEN
            },
            paramName: 'file',
            maxFilesize: '8',
            acceptedFiles: '.csv',
            addRemoveLinks: true,
            uploadMultiple: true,
            maxFiles: 1,
            timeout: 300000,
            parallelUploads: 1,
            dictRemoveFile: 'Dismiss Upload',
        };

        $scope.dzCallbacks = {
            'sending': function (file) {
                toastr.info('Your file is uploading. Please wait...');
                var dz = this.Dropzone.forElement('#import-dropzone');
                dz.removeEventListeners(); //Disable Dropzone
            },
            'success': function (file, xhr) {
                toastr.success('Your file has been successfully imported.');
                $scope.importSuccess = true;
                $scope.processResults(xhr.data);
            },
            'error': function (file, errorMessage, xhr) {
                if (!xhr || !xhr.response) { // Handle scenario in which user selects a file type that is not accepted
                    toastr.error('The file you selected is invalid!');
                    var dz = this.Dropzone.forElement('#import-dropzone');
                    dz.removeFile(dz.files[0]);
                    return;
                }

                var response = false;
                try {
                    response = JSON.parse(xhr.response);
                } catch (error) {
                    //
                }
                if (!response) { // Handle the scenario in which a user chooses a malformed file
                    toastr.error('The file you selected is invalid or we were unable to process it!');
                    return;
                }
                if (response.errors) {
                    toastr.error('Failed to import your file! ');
                    $scope.importError = true;
                    if (response.errors.file) {
                        $scope.importErrors = response.errors.file;
                    }
                }

                $scope.processResults(response);
            },
            'complete': function (file) {
                var dz = this.Dropzone.forElement('#import-dropzone');
                dz.removeFile(dz.files[0]);
                dz.setupEventListeners(); //Enable Dropzone
            }
        };
        $scope.dzMethods = {};

        $scope.reset = function () {
            $scope.importError = false;
            $scope.importSuccess = false;
            $scope.importErrors = [];
            $scope.importResults = [];
            $scope.importedRows = 0;
        }; 

        $scope.reset();
        $scope.processResults = function (response) {
            $scope.importResults = ($scope.importError && response && response.errors && response.errors.results) ? response.errors.results : $scope.importResults;
            if ($scope.importSuccess && response ) {
                $scope.importResults = response.results ? Object.values(response.results) : $scope.importResults;
                $scope.importedRows = response.imported ? response.imported : 0;
            }
        };
        $scope.getRowBgColor = function (rowType) {
            return rowType == 'error' ? { 'background-color': '#ffcccc' } : {};
        }
    });