optoviewModule.directive('optoCreditCardForm', [function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/creditCard/form.html',
        $scope: {
            'initialize-cc': "&initializeCc"
        },
        controller: ['$scope', '$http', 'toastr', 'stripe', 'Plans', function ($scope, $http, toastr, stripe, Plans) {
            $scope.ccInfo = {};
            $scope.ccYears = [];
            $scope.ccErrors = {};
            $scope.ccMonths = {
                1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June',
                7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'
            };

            var currentYear = parseInt(moment().format('YYYY'));

            for (var i = 0; i < 15; i++) {
                $scope.ccYears.push(currentYear + i);
            }
            $scope.user.chosen_price = undefined;
            $userRole = $scope.chosen_role || $scope.user.role;
            $scope.allowSelectPlan = $scope.chosen_role == undefined ? true : false; //Only allow chanign the plan if we are on teh register workflow
            if ($userRole) {
                Plans.getForRole($userRole)
                    .then(function (data) {
                        $scope.plans = data.data;
                        if (!$scope.user.chosen_price && $scope.plans && $scope.plans.length > 0 && $scope.plans[0].prices && $scope.plans[0].prices.length > 0)
                            $scope.user.chosen_price = $scope.plans[0].prices[0].id;
                    });
            } else {
                $scope.plans = [];
            }
            $scope.processCreditCard = function (successCallback) {
                runValidation();

                if (angular.equals({}, $scope.ccErrors)) {
                    stripe.card.createToken({
                        name: $scope.ccInfo.name,
                        number: $scope.ccInfo.number,
                        cvc: $scope.ccInfo.cvc,
                        exp_month: $scope.ccInfo.expiry_month,
                        exp_year: $scope.ccInfo.expiry_year
                    })
                        .then(function (response) {
                            successCallback(response);
                        })
                        .catch(function (error) {
                            if (error.code == "invalid_expiry_month" || error.param == "card[exp_month]") {
                                $scope.ccErrors.expiry_month = "Your card's expiration month is invalid.";
                            } else if (error.code == "invalid_expiry_year" || error.param == "card[exp_year]") {
                                $scope.ccErrors.expiry_year = "Your card's expiration year is invalid.";
                            } else {
                                toastr.error('There was an unknown error.');
                            }
                        });
                }
            };

            $scope.clearCardInfo = function () {
                $scope.ccInfo.name = '';
                $scope.ccInfo.number = '';
                $scope.ccInfo.cvc = '';
                $scope.ccInfo.expiry_month = '';
                $scope.ccInfo.expiry_year = '';
            };

            function runValidation() {
                $scope.ccErrors = {};
                if (!$scope.ccInfo.name) {
                    $scope.ccErrors.name = 'A name for the credit card is required.';
                }

                if (!$scope.ccInfo.number) {
                    $scope.ccErrors.number = 'A proper credit card number is required.';
                } else if (!stripe.card.validateCardNumber($scope.ccInfo.number)) {
                    $scope.ccErrors.number = "Invalid card number.";
                }

                if (!$scope.ccInfo.expiry_month) {
                    $scope.ccErrors.expiry_month = 'An expiry month is required.';
                }

                if (!$scope.ccInfo.expiry_year) {
                    $scope.ccErrors.expiry_year = 'An expiry year is required.';
                }

                if (!$scope.ccInfo.cvc) {
                    $scope.ccErrors.cvc = 'A CVC number is required.';
                } else if (!stripe.card.validateCVC($scope.ccInfo.cvc)) {
                    $scope.ccErrors.cvc = "Invalid CVC number.";
                }

                if (!$scope.user.chosen_price) {
                    $scope.ccErrors.chosen_price = 'Please select a pricing plan!';
                }
            }

            $scope.initializeCc({
                processCreditCard: $scope.processCreditCard,
                clearCardInfo: $scope.clearCardInfo
            });
        }]
    };
}]);