var videoService = function (helpers, $http, toastr) {
  var thisService = this;
  this.selectedVideo = {};
  this.annotating = false;
  this.loading = true;
  this.busy = false;
  this.annotatigAnnotatedImage = false;

  this.annotator = new Annotator();

  this.destroyVideo = function () {
    //When user leaves view, clean up active video player and fabricjs.
    if (angular.isDefined(this.videoPlayer)) {
      if (this.videoPlayer) {
        this.videoPlayer.pause();
        this.videoPlayer.dispose();
      }
      $('#videoDiv').html('');
      this.videoPlayer = null;
    }
  };

  this.annotateFrame = function () {
    if (!this.loading) {
      this.videoPlayer.pause();
      this.annotator.initCanvas();
      var w = $('#videoDiv').width();
      var h = $('#videoDiv').height();
      this.annotator.fabricjs.setWidth(w);
      this.annotator.fabricjs.setHeight(h);
      this.annotator.addImageFromVideoToCanvas(
        this.fabricjs,
        this.video,
        0,
        0,
        w,
        h
      );
      this.annotating = true;
      this.annotatigAnnotatedImage = false;
    }
  };

  this.initVideo = function () {
    this.destroyVideo();
    $('#videoDiv').html(
      '<video style="max-width:100%;min-height:300px" id="exam-video" class="video-js" controls></video>'
    );
    this.videoPlayer = videojs(
      'exam-video',
      {
        fluid: true,
        preload: 'auto',
        inactivityTimeout: 0,
        muted: true,
        controlBar: {
          volumeMenuButton: false,
          muteToggle: false,
          fullscreenToggle: false,
          pictureInPictureToggle: false
        },
        playbackRates: [0.065, 0.5, 1],
        plugins: {
          framebyframe: {
            fps: 60,
            steps: [
              { text: ' < ', step: -1 },
              { text: ' > ', step: 1 }
            ]
          }
        },
        hotkeys: {
          seekStep: 1
        }
      },
      function () {
        thisService.video = $('#exam-video_html5_api')[0];
        $('#exam-video').removeClass('vjs-playing').addClass('vjs-paused');
        if (thisService.annotator.shouldRotate()) {
          thisService.videoPlayer.zoomrotate({
            rotate: 90,
            zoom: 1
          });
        }
        thisService.videoPlayer.play().then(function () {
          thisService.videoPlayer.pause();
        });

        //Mute the video playback & hide the volume control & mute button
        thisService.videoPlayer.on('play', function () {
          thisService.videoPlayer.volume(0);
          thisService.videoPlayer.controlBar.volumePanel.hide();
          thisService.videoPlayer.controlBar.volumePanel.volumeControl.hide();
          thisService.videoPlayer.controlBar.volumePanel.muteToggle.hide();
        });

        thisService.videoPlayer.on('loadedmetadata', function () {
          setTimeout(function () {
            thisService.loading = false;
          }, 500);
        });
      }
    );
  };

  this.destroy = function () {
    this.annotator.destroyCanvas();
    this.destroyVideo();
  };

  this.cancelSave = function () {
    this.annotating = false;
    this.annotatigAnnotatedImage = false;
    this.annotator.destroyCanvas();
  };

  this.reset = function () {
    this.cancelSave();
    this.selectedVideo = {};
  };

  this.init = function () {
    this.annotator.initCanvas();
  };

  this.selectVideo = function (video, autoplay) {
    this.annotator.destroyCanvas();
    this.initVideo();
    var autoplay = autoplay ? true : false;
    this.annotating = false;
    this.annotatigAnnotatedImage = false;
    this.selectedVideo = video;
    this.loadVideo(video.url, autoplay, video.thumbnail_url);
  };

  this.saveFrame = function (examVideo, exam) {
    this.annotator.fabricjs.deactivateAll().renderAll();
    const imageData = this.annotator.canvas.toDataURL('image/jpeg', 0.95);
    let imageToSave = {};
    let editMode = false;
    let index = null;
    this.busy = true;
    //FIrst check if we are saving an already annotated image
    if (this.annotatigAnnotatedImage) {
      index = helpers.findIdBy(exam.addedImages, this.annotatigAnnotatedImage);
      if (index) {
        exam.addedImages[index].data = imageData;
        imageToSave = exam.addedImages[index];
        editMode = true;
      }
    } else {
      //Process as newly annotated image if nothing before this processed teh image save
      imageToSave = {
        id: Date.now(),
        video_id: examVideo.id,
        side: examVideo.side,
        data: imageData,
        timestamp: this.videoPlayer.currentTime() || 0
      };
    }
    //If no images have been added yet, create the added images array to add to.
    if (!exam.addedImages) {
      exam.addedImages = [];
    }
    // exam.addedImages.unshift(newImage);

    this.saveImageToDB(imageToSave, editMode)
      .then((response) => {
        toastr.success('Image was saved');
        if (!editMode) {
          imageToSave.id = response.data.data.id;
          exam.addedImages.unshift(imageToSave);
        }
        this.cancelSave();
      })
      .catch((errorResponse) => {
        console.error(errorResponse);
        toastr.error('Cannot save image');
      })
      .finally(() => {
        this.busy = false;
      });
  };

  this.saveImageToDB = function (image, edit) {
    // SAVE IMAGE TO AWS
    return $http({
      method: 'POST',
      url: `/exam/upload-image${edit ? `/${image.id}` : ''}`,
      data: image
    });
  };

  this.removeImageToDB = function (imageId) {
    // SAVE IMAGE TO AWS
    return $http({
      method: 'DELETE',
      url: `/exam/upload-image/${imageId}`
    });
  };

  this.goToTimestamp = function (video, timestamp) {
    if (this.selectedVideo.id != video.id) {
      this.selectVideo(video, false);
    }
    if (!this.loading) {
      thisService.videoPlayer.pause();
      thisService.videoPlayer.currentTime(timestamp + 1 / 60);
      thisService.videoPlayer.userActive(true);
    }
  };

  this.setPoster = function (image_url) {
    thisService.videoPlayer.poster(image_url);
    thisService.videoPlayer.trigger('posterchange');
  };

  this.deleteImage = function (exam, imageId) {
    this.busy = true;
    this.removeImageToDB(imageId)
      .then(() => {
        var index = helpers.findIdBy(exam.addedImages, imageId);
        exam.addedImages.splice(index, 1);
      })
      .catch((error) => {
        toastr.error('Cannot delete image');
        console.error(error);
      })
      .finally(() => {
        this.busy = false;
      });
  };

  this.annotateImage = function (exam, imageId) {
    if (this.annotating) {
      return;
    }
    var index = helpers.findIdBy(exam.addedImages, imageId);
    this.videoPlayer.pause();
    this.annotator.initCanvas();
    var w = $('#videoDiv').width();
    var h = $('#videoDiv').height();
    this.annotator.fabricjs.setWidth(w);
    this.annotator.fabricjs.setHeight(h);
    const image = exam.addedImages[index];
    this.annotator.addImageToCanvas(this.fabricjs, image, 0, 0, w, h);
    this.annotating = true;
    this.annotatigAnnotatedImage = imageId;
  };

  this.annotateIqaImage = function (video, exam, timestamp, imageUrl) {
    if (!this.annotating && !this.loading) {
      this.goToTimestamp(video, timestamp);
      var newImage = {
        id: Date.now(),
        video_id: video.id,
        side: video.side,
        data: imageUrl,
        timestamp: timestamp
      };
      this.videoPlayer.pause();
      this.annotator.initCanvas();
      var w = $('#videoDiv').width();
      var h = $('#videoDiv').height();
      this.annotator.fabricjs.setWidth(w);
      this.annotator.fabricjs.setHeight(h);
      this.annotator.addImageToCanvas(this.fabricjs, newImage, 0, 0, w, h);
      this.annotating = true;
      this.annotatigAnnotatedImage = false;
    }
  };

  this.loadVideo = function (url, autoplay, thumbnail) {
    this.loading = true;
    thisService.videoPlayer.src([{ type: 'video/mp4', src: url }]);
    if (thumbnail) {
      thisService.setPoster(thumbnail);
    }

    if (autoplay ? true : false) {
      thisService.videoPlayer.play();
    }
  };
};

angular.module('videoService', []).factory('videoService', [
  'helpers',
  '$http',
  'toastr',
  function (helpers, $http, toastr) {
    return {
      instance: function () {
        return new videoService(helpers, $http, toastr);
      }
    };
  }
]);
