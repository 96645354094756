Dropzone.autoDiscover = false;
var optoviewModule = angular
    .module('OptoviewModule', [
        'ui.bootstrap',
        'ui.router',
        'restangular',
        'ngTable',
        'toastr',
        'angular-stripe',
        'ui.keypress',
        'xeditable',
        'angular-loading-bar',
        'easyTable',
        'apg.typeaheadDropdown',
        'currentUser',
        'helpers',
        'singleClick',
        'pendingExamCount',
        'examTypes',
        'videoService',
        'thatisuday.dropzone'
    ])
    .factory('errorInterceptor', function($q, toastr) {
        return {
            responseError: function (response) {
                if (response.status == 404) {
                    // toastr.warning('Record not found');
                } else if (response.status == 500) {
                    toastr.error('Unknown error occurred');
                } else if(response.status == 400) {
                    toastr.error(response.data.message);
                } else if(response.status == 401) {
                    toastr.error('Unauthorized: ' + response.data.message);
                } else if (response.status == 422) {
                    try {
                        if(response.data.message && response.data.message != 'Unprocessable Entity'){
                            toastr.warning(response.data.message);
                        }
                    } catch(e) {}
                }
                return $q.reject(response);
            }
        }
    })
    .run(function(editableOptions, editableThemes) {
        editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
        editableThemes.bs3.inputClass = 'input-sm';
        editableThemes.bs3.buttonsClass = 'btn-sm';
    })
    .run(function(examTypes){
        examTypes.get();
    })
    .config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {
        //cfpLoadingBarProvider.includeSpinner = false;
        cfpLoadingBarProvider.parentSelector = '#loading-bar-container';
    }])
    .config(function (stripeProvider) {
        stripeProvider.setPublishableKey(stripePublicKey);
    });