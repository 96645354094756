optoviewModule.controller('CreateOphthalmologistController', function($scope, Users, $uibModalInstance, toastr) {
    $scope.user = {status: "Pending Email"};
    $scope.title = 'Create Specialist';
    $scope.templateBody = '/templates/user/info.html';
    $scope.chosen_role = "Ophthalmologist";
    $scope.isOphthalmologist = true;
    $scope.newUser = true;
    $scope.currentUserIsAdmin = true;

    if ($scope.currentUserIsAdmin) {
        $scope.user.needs_subscription = 0;
    } else {
        $scope.user.needs_subscription = 1;
    }

    $scope.submit = function (response) {
        Users.createOphthalmologist($scope.user).then(function () {
            toastr.success($scope.user.name + ' user account created.');
            $uibModalInstance.close();
        }, function(response) {
            $scope.formErrors = response.data;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});