optoviewModule.controller('PatientController',
    function ($scope, Patients, $stateParams, helpers, toastr, subState, $location) {

        /**
         * Sub state
         */
        $scope.subState = subState;

        /**
         * The helpers lib
         */
        $scope.helpers = helpers;

        /**
         * The selected patient UUID
         */
        $scope.currentPatientId = $stateParams.patientId;

        /**
         * The selected patient data
         */
        $scope.selectedPatient = {};

        /**
         * Validation errors
         */
        $scope.errors = {};

        $scope.date_of_birth_datepicker_open = false;
        /**
         * Set a patient's data into scope
         * @param {Object} patient_data 
         */
        $scope.setSelectedPatient = function (patient_data) {
            $scope.selectedPatient = patient_data;
            if (subState == 'view') {
                $scope.selectedPatient.date_of_birth = helpers.formatDT($scope.selectedPatient.date_of_birth);
                $scope.getPatientExams($scope.selectedPatient.id);
            } else {
                $scope.selectedPatient.date_of_birth = Date.parse($scope.selectedPatient.date_of_birth);
            }
        };

        /**
         * Get the patient from BE
         * @param {UUID} patientId 
         */
        $scope.getPatient = function (patientId) {
            Patients.get(patientId).then((response) => {
                $scope.setSelectedPatient(response.data);
            });
        };

        /**
         * Get the patient's past exams
         * @param {UUID} patientId 
         */
        $scope.getPatientExams = function (patientId) {
            Patients.getExams(patientId).then(function (response) {
                $scope.pastExams = response.data;
            });
        };

        $scope.submitPatient = function () {
            var patientData = Object.assign({}, $scope.selectedPatient);
            delete patientData.physician;
            if (patientData.date_of_birth) {
                patientData.date_of_birth = helpers.formatDT(patientData.date_of_birth, 'YYYY-MM-DD 00:00:00');
            }
            var save = subState == 'add' ? Patients.create(patientData) : Patients.update(patientData);
            save
                .then((response) => {
                    if (response.message == 'Success') {
                        $location.path('/patients');
                        toastr.success(" Patient " + response.data.name + " was saved successfully!");
                    } else {
                        $scope.errors = response.data.errors ? response.data.errors : {};
                        toastr.error("Error saving the patient!");
                    }
                }, (errResponse) => {
                    $scope.errors = errResponse.data.errors ? errResponse.data.errors : {};
                    toastr.error("Error saving the patient!");
                });
        }
        /**Load the patient if not already in scope */
        if ($scope.selectedPatient.id !== undefined || $scope.selectedPatient.id != $scope.currentPatientId)
            $scope.getPatient($scope.currentPatientId);

    });