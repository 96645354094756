function Annotator() {
    var annotator = this;
    this.drawingColor = '#FFFFFF';

    //In browsers which do not read orientation correctly return true.
    this.shouldRotate = function () {
        if (bowser.firefox) {
            return true;
        }
        return false;
    };

    this.destroyCanvas = function () {
        if (angular.isDefined(this.fabricjs)) {
            this.fabricjs.dispose();
            $(this.fabricjs.wrapperEl).remove();
            $('#canvasWrapper').html('');
        }
    };

    this.initCanvas = function () {
        $('#drawing-color').spectrum({
            color: '#FFFFFF',
            change: function (color) {
                annotator.updateColor(color.toHexString());
            }
        });
        this.destroyCanvas();
        $('#canvasWrapper').html('<canvas id="examVideoCanvas"></canvas>');
        this.canvas = $('#examVideoCanvas')[0];
        this.fabricjs = new fabric.Canvas('examVideoCanvas', {
            selection: false,
            isDrawingMode: false,
            backgroundColor: '#000000'
        });
        //When line is drawn make it so it can't be moved.
        this.fabricjs.on('path:created', function (e) {
            e.path.selectable = false;
        });
        //Set default drawing color.
        this.fabricjs.freeDrawingBrush.color = '#FFFFFF';
    };

    this.updateColor = function (color) {
        this.drawingColor = color;
        this.fabricjs.freeDrawingBrush.color = color;
    };

    this.getBiggestDimension = function (sourceImage) {
        return sourceImage.width > sourceImage.height ? sourceImage.width : sourceImage.height;
    };

    this.needsToRotate = function (video) {
        var tempImage = document.createElement('canvas');
        tempImage.width = video.offsetWidth;
        tempImage.height = video.offsetHeight;
        var ctx = tempImage.getContext("2d");
        ctx.drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);
        var heightPixel = ctx.getImageData(1, tempImage.height - 1, 1, 1).data;
        if (heightPixel[3] == 0) {
            console.log('rotated');
            return true;
        }
    };

    this.addImageFromVideoToCanvas = function (canvas, video, x, y, w, h) {
        // var rotate = this.needsToRotate(video);
        var rotate = false;
        var tempImage = document.createElement('canvas');

        var finalWidth = rotate ? h : w;
        var finalHeight = rotate ? w : h;

        tempImage.width = finalWidth;
        tempImage.height = finalHeight;
        var ctx = tempImage.getContext("2d");
        ctx.drawImage(video, 0, 0, finalWidth, finalHeight);
        this.cropImageFromCanvas(ctx, tempImage);
        var imageInstance = new fabric.Image(tempImage, {
            left: x,
            top: y,
            width: finalWidth,
            height: finalHeight,
            lockMovementX: true,
            lockMovementY: true,
            hasControls: false,
            selectable: false
        });
        this.fabricjs.add(imageInstance);
        this.fabricjs.moveTo(imageInstance, -1);
        if (this.shouldRotate() || rotate) {
            imageInstance.rotate(90);
            imageInstance.center();
        }
    };

    this.addImageToCanvas = function (canvas, image, x, y, w, h) {
        var tempImage = document.createElement('canvas');
        tempImage.width = w;
        tempImage.height = h;
        var ctx = tempImage.getContext("2d");
        var img = new Image();
        img.src = image.data;
        const that = this;
        img.onload = function () {
            ctx.drawImage(img, 0, 0, w, h);
            var imageInstance = new fabric.Image(tempImage, {
                left: x,
                top: y,
                width: w,
                height: h,
                lockMovementX: true,
                lockMovementY: true,
                hasControls: false,
                selectable: false
            });
            that.fabricjs.add(imageInstance);
            that.fabricjs.moveTo(imageInstance, -1);
            if (that.shouldRotate()) {
                imageInstance.rotate(90);
                imageInstance.center();
            }
        }

    };

    this.addTextToCanvas = function () {
        //stop drawing when adding text.
        this.fabricjs.isDrawingMode = false;
        var textObject = new fabric.IText('double click to edit', {
            fontSize: 20,
            fill: this.drawingColor,
            lockRotation: true,
            lockScalingFlip: true,
            fontFamily: "arial",
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true
        });
        this.fabricjs.add(textObject);
    };

    this.draw = function () {
        this.fabricjs.isDrawingMode = this.fabricjs.isDrawingMode ? false : true;
    };

    this.cropImageFromCanvas = function (ctx, canvas) {

        var w = canvas.width,
            h = canvas.height,
            pix = { x: [], y: [] },
            imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
            x, y, index;

        for (y = 0; y < h; y++) {
            for (x = 0; x < w; x++) {
                index = (y * w + x) * 4;
                if (imageData.data[index + 3] > 0) {
                    pix.x.push(x);
                    pix.y.push(y);
                }
            }
        }
        pix.x.sort(function (a, b) { return a - b });
        pix.y.sort(function (a, b) { return a - b });
        var n = pix.x.length - 1;

        w = pix.x[n] - pix.x[0];
        h = pix.y[n] - pix.y[0];
        var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

        canvas.width = w;
        canvas.height = h;
        ctx.putImageData(cut, 0, 0);
    }
};