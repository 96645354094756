optoviewModule.controller('UserListController', function ($http, Users, easyTable, toastr, $uibModal, helpers) {

    vm = this;
    vm.helpers = helpers;
    vm.selectedRole = '';
    vm.selectedStatus = '';
    vm.roles = [
        { role: '', text: 'All' },
        { role: 'Admin', text: 'Admin' },
        { role: 'Physician', text: 'Physician' },
        { role: 'Ophthalmologist', text: 'Ophthalmologist' }
    ];

    vm.statuses = [
        { name: '', text: 'All' },
        { name: 'enabled', text: 'Enabled' },
        { name: 'disabled', text: 'Disabled' },
        { name: 'Pending Email', text: 'Pending' }
    ];

    vm.userTable = easyTable.create(Users, {
        data: function () {
            return {
                user_role: vm.selectedRole,
                user_status: vm.selectedStatus,
                user_name: vm.userName
            }
        },
        defaultSort: { registration_date: 'desc' }
    });
    vm.openEditModal = function (user) {
        $uibModal.open({
            animation: true,
            templateUrl: '/templates/layout/modal.html',
            controller: 'UpdateUserController',
            resolve: {
                user: function () {
                    return user;
                }
            }
        }).result.then(function () {
                vm.userTable.table.reload();
            });
    };

    vm.openCreateAdminModal = function () {
        $uibModal.open({
            animation: true,
            templateUrl: '/templates/layout/modal.html',
            controller: 'CreateAdminController'
        }).result.then(function () {
            vm.userTable.table.reload();
        });
    };

    vm.openCreateUserModal = function (role) {
        if (role == 'Physician') {
            $uibModal.open({
                animation: true,
                templateUrl: '/templates/layout/modal.html',
                controller: 'CreatePhysicianController'
            }).result.then(function () {
                vm.userTable.table.reload();
            });
        } else if (role == 'Specialist') {
            $uibModal.open({
                animation: true,
                templateUrl: '/templates/layout/modal.html',
                controller: 'CreateOphthalmologistController'
            }).result.then(function () {
                vm.userTable.table.reload();
            });
        } else {
            return;
        }
    };

    vm.updateStatus = function (user, status) {
        Users.updateStatus(user.id, status).then(function () {
            toastr.success('User ' + user.name + ' status updated to ' + status);
            user.status = status;
            user.selectStatus = false;
        });
    };

    vm.updateUser = function (data) {
        Users.update(data);
    };

    vm.updateSelectedRole = function (role) {
        vm.selectedRole = role.role;
        vm.userTable.table.reload();
    };

    vm.updateSelectedStatus = function (status) {
        vm.selectedStatus = status.name;
        vm.userTable.table.reload();
    };

    vm.deleteUser = function (user) {
        Users.delete(user).then(function () {
            toastr.success('User ' + user.name + ' deleted');
            vm.userTable.table.reload();
        });
    };

    vm.userTable.table.reload();
});