optoviewModule.directive('optoUserInfoForm', [function () {
    return {
        restrict: 'E',
        templateUrl: 'templates/user/info.html',
        controller: ['$scope', '$http', 'toastr', 'currentUser', function ($scope, $http, toastr, currentUser) {
            $scope.formErrors = {};
            $scope.user = (currentUser) ? currentUser : {};

            $scope.submitUser = function (successCallback) {
                $http({
                    method: 'POST',
                    url: routes.store.ophthalmologist,
                    data: $scope.user
                })
                    .then(function success() {
                        $scope.formErrors = [];
                        successCallback();
                    }, function error(response) {
                        if (response.status == 422) {
                            toastr.error('Errors saving signup data. Please see highlighted field(s).');
                        }
                        $scope.formErrors = response.data;
                    });
            };
        }]
    };
}]);