// optoviewModule.controller('InviteController', ['$scope', '$http', 'toastr', 'helpers', 'Invite'], function ($scope, $http, toastr, helpers, Invite) {
optoviewModule.controller('InviteController', function ($http, Invite, $scope, toastr, helpers) {

    const rowTemplate = {
        first_name: "",
        last_name: "",
        email: "",
    };
    $scope.helpers = helpers;

    $scope.invites = [
        Object.assign({}, rowTemplate)
    ];

    $scope.addRow = function () {
        if ($scope.invites.length < 10) {
            $scope.invites.push(Object.assign({}, rowTemplate));
        }
    };
    $scope.validate = function () {
        var filteredRows = [];
        var errors = false;
        $scope.invites.forEach(function (row) {
            if (
                row.first_name.length == 0
                && row.last_name.length == 0
                && row.email.length == 0
            ) {
                return;
            }
            row.errors = {};
            if (!row.first_name || row.first_name.length == 0) {
                row.errors.first_name = "The first name is required.";
                errors = true;
            }
            if (!row.last_name || row.last_name.length == 0) {
                row.errors.last_name = "The last name is required.";
                errors = true;
            }
            if (!row.email || row.email.length == 0) {
                row.errors.email = "The email is required.";
                errors = true;
            }
            filteredRows.push(row);
        });
        if (filteredRows.length == 0) {
            var emptyRow = Object.assign({}, rowTemplate);
            emptyRow.errors = {};
            emptyRow.errors.first_name = "The first name is required.";
            emptyRow.errors.last_name = "The last name is required.";
            emptyRow.errors.email = "The email is required.";
            $scope.invites = [emptyRow];
            return false;
        } else {
            $scope.invites = filteredRows;
            $scope.capitalizenames();
        }
        return !errors;
    };
    $scope.processForm = function () {
        if (!$scope.validate()) {
            toastr.error("Please correct the errors below and try again!");
            return;
        }
        Invite.sendInvites($scope.invites)
            .then(function (response) {
                if (response && response.data && response.data.length > 0) {
                    var filteredRows = [];
                    response.data.forEach(function (val, index) {
                        if (!val['status']) {
                            $scope.invites[index].rowError = val['message'];
                            filteredRows.push($scope.invites[index]);
                        }
                    });
                    if (filteredRows.length == 0) {
                        $scope.invites = [
                            Object.assign({}, rowTemplate)
                        ];
                        toastr.success("Invitation sent!");
                        return;
                    }
                    if (filteredRows.length < $scope.invites.length) {
                        toastr.success("The invitation was sent to some people but not all of them!");
                    } else {
                        toastr.error("Please correct the errors below and try again!");
                        return;
                    }
                    $scope.invites = filteredRows;
                } else {
                    toastr.error("There was an error submitting your request!");
                    return;
                }
            })
            .catch(function (err) {
                if (err && err.data) {
                    var response = err.data;
                    //Handle request validation errors
                    if (response.errors) {
                        for (const error in response.errors) {
                            const errorDetails = error.split('.');
                            const index = errorDetails[1];
                            const field = errorDetails[2];
                            const errorText = response.errors[error][0].replace(/invites\.\d*\./g, "");
                            if (!$scope.invites[index].errors) {
                                $scope.invites[index].errors = {};
                            }
                            $scope.invites[index].errors[field] = errorText;
                        }
                    }
                    toastr.error("Please correct the errors below and try again!");
                    return;
                }
                toastr.error("There was an error submitting your request!");
                return;
            });
    };
    $scope.capitalizeWords = function (input) {
        input = input.trim();
        if (input.length == 0) {
            return "";
        }
        var words = input.split(' ');
        var CapitalizedWords = [];
        words.forEach(element => {
            if (element.length > 0) {
                CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
            } else {
                CapitalizedWords.push("");
            }
        });
        return CapitalizedWords.join(' ');
    };

    $scope.capitalizenames = function () {
        $scope.invites.map(function (row) {
            row.first_name = $scope.capitalizeWords(row.first_name);
            row.last_name = $scope.capitalizeWords(row.last_name);
            return row;
        });
    };
});
