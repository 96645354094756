optoviewModule.controller('UpdateUserController', function ($scope, Users, user, $uibModalInstance, toastr) {
    $scope.user = user;
    $scope.hidePasswords = true;
    $scope.dontShowReset = true;
    $scope.title = 'Edit User';
    $scope.currentUserIsAdmin = true;
    $scope.templateBody = '/templates/user/info.html';

    $scope.submit = function (user) {
        $scope.user = user;

        if ($scope.isOphthalmologist) {
            $scope.user.exam_capabilities = [];
            if ($scope.user.isDRCapable) {
                $scope.user.exam_capabilities.push('DigitalRetinopathy');
            }
            if (user.isDPCapable) {
                user.exam_capabilities.push('DigitalPathology');
            }
        }

        Users.update($scope.user)
            .then(function (response) {
                $scope.formErrors = [];
                $uibModalInstance.close();
                toastr.success('Updated ' + $scope.user.name + ' profile information.');
                vm.userTable.table.reload();
            })
            .catch(function (errorResponse) {
                console.error(errorResponse);
                toastr.error('Unable to save changes. Unknown error.');
                $scope.formErrors = errorResponse.data ? errorResponse.data : [];
            });
    };

    $scope.isOphthalmologist = function () {
        if (!$scope.user.exam_capabilities) {
            return false;
        }
        return $scope.user.role == "Ophthalmologist";
    }

    $scope.isCapable = function (cap) {
        if (!$scope.user.exam_capabilities) {
            return false;
        }
        return $scope.user.exam_capabilities.includes(cap);
    }

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.user.isDRCapable = $scope.isCapable("DigitalRetinopathy");
    $scope.user.isDPCapable = $scope.isCapable('DigitalPathology');
});
