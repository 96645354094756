optoviewModule.service('Services', function (Restangular) {
    return {
        make: function (endpoint) {
            var point = Restangular.all(endpoint);
            return {
                service: point,
                methods: {
                    all: function () {
                        return Restangular.all(endpoint).getList();
                    },
                    where: function (params) {
                        return point.post(params);
                    },
                    get: function (id) {
                        return point.one(id).get();
                    },
                    update: function (obj) {
                        return Restangular.all(endpoint + '//update').post(obj);
                    },
                    delete: function (obj) {
                        return point.one(obj.id).remove();
                    },
                    createNested: function (parent, obj, route) {
                        return parent.post(route, obj);
                    },
                    fromNested: function (parent, collection, route) {
                        return _.map(collection, function (item) {
                            return Restangular.restangularizeElement(parent, item, route);
                        });
                    }
                }
            }
        }
    };
})
    .service('Exams', function (Services, Restangular) {
        var $service = Services.make('myExams');
        $service.methods.decline = function (id) {
            return Restangular.service('exams/decline/' + id).post();
        };
        $service.methods.assign = function (examId, userId) {
            return Restangular.service('/exam/assignOphthalmologist').post({ exam_id: examId, user_id: userId });
        };
        $service.methods.getPendingCount = function (userId) {
            return Restangular.service('/api/exams/countByStatus').post({ statuses: ['Pending', 'Saved'] });
        };
        return $service.methods;
    })
    .service('Patients', function (Services, Restangular) {
        var $service = Services.make('patients');
        $service.methods.getExams = function (patientId) {
            return Restangular.service('/api/getUserExams').post({
                patient_id: patientId,
                status: ['Pending', 'Saved', 'Reviewed', 'Opened']
            });
        };
        $service.methods.update = function (patient) {
            return Restangular.service('/patients/' + patient.id).post(patient);
        }
        $service.methods.create = function (patient) {
            return Restangular.service('/patients/create').post(patient);
        }
        $service.methods.import_csv = function (file) {
            var fd = new FormData();
            fd.append('file', file, file.name);
            return Restangular.service('/patients/import').post(fd);
        }
        return $service.methods;
    })
    .service('Invite', function (Services, Restangular) {
        var $service = Services.make('invite');
        $service.methods.sendInvites = function (invites) {
            return Restangular.service('/api/invite').post({
                invites: invites,
            });
        };
        return $service.methods;
    })
    .service('Users', function (Services, Restangular) {
        var $service = Services.make('users');
        $service.methods.updateStatus = function (id, status) {
            return Restangular.service('users/update').post({ id: id, status: status });
        };
        $service.methods.createAdmin = function (user) {
            return Restangular.service('users/create/admin').post(user);
        };
        $service.methods.createPhysician = function (user) {
            return Restangular.service('users/create/physician').post(user);
        };
        $service.methods.createOphthalmologist = function (user) {
            return Restangular.service('users/create/ophthalmologist').post(user);
        };
        return $service.methods;
    }).service('User', function (Services) {
        var $service = Services.make('user');
        return $service.methods;
    })
    .service('Plans', function (Services, Restangular) {
        var $service = Services.make('plans');
        $service.methods.getForRole = function (userType) {
            return Restangular.service('plans/' + userType).one().get();
        };
        return $service.methods;
    })
    .service('ExamTypes', function (Services, Restangular) {
        var $service = Services.make('api/exam-type');
        $service.methods.get = function () {
            return Restangular.service('/api/exam-type').one('').get();
        };
        return $service.methods;
    })
    .service('RequestReassignment', function (Services, Restangular) {
        var $service = Services.make('reassignmentRequests');
        $service.methods.create = function (request) {
            return Restangular.service('reassignmentRequest/create').post(request);
        };
        $service.methods.update = function (requestId, status) {
            return Restangular.service('reassignmentRequest/update').post({ request_id: requestId, status: status});
        }
        $service.methods.getRequestInfo = function(requestId) {
            return Restangular.service('reassignmentRequest').one(requestId).get();
        }
        return $service.methods;
    });