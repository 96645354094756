optoviewModule.controller('ReassignmentRequestController', function ($http, $scope, RequestReassignment, toastr, currentUser, exam, users, $uibModalInstance) {
    $scope.exam = exam;
    $scope.users = users;
    $scope.title = 'Reassign this Exam';
    $scope.currentUser = currentUser;
    $scope.templateBody = '/templates/exam/reassignmentRequest.html';
    $scope.reassignmentRequest = {
        current_assignee_id: $scope.exam.ophthalmologist ? $scope.exam.ophthalmologist.data.id : null,
        requester_id: $scope.currentUser.id,
    };
    $scope.getCurrentAssignee = function () {
        return ($scope.exam.ophthalmologist ? $scope.exam.ophthalmologist.data.name : 'Not Assigned');
    }

    $scope.userCanReviewExam = function(spec) {
        // filter according to exam review capabilities and location (state)
        if ($scope.exam.ophthalmologist) { 
            console.log($scope.exam.ophthalmologist);
            return (spec.practice.data.state == $scope.exam.ophthalmologist.data.practice.data.state 
            && spec.exam_capabilities.includes($scope.exam.type));

        } else {
            return spec.exam_capabilities.includes($scope.exam.type);
        }
    }

    $scope.submit = function () {
        $scope.reassignmentRequest.exam_id = $scope.exam.id;
        RequestReassignment.create($scope.reassignmentRequest).then(function (res) {
            $uibModalInstance.close(res);
        }).catch(function (errorResponse) {
            toastr.error('Unable to submit. Unknown error.');
            $scope.formErrors = errorResponse.data ? errorResponse.data : [];
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});