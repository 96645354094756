optoviewModule.controller('MetricsController', function($http, $scope, toastr, helpers) {
    $http({
        method: 'GET',
        url: '/exam/getFollowedUpCount'
    }).then(function(response) {
        $scope.followedUpCount = response.data.data;
    });

    $http({
        method: 'GET',
        url: '/exam/getCountPerUsers'
    }).then(function(response) {
        $scope.countPerUsers = response.data.data;
    });
});