optoviewModule.controller('ExamController', ['$scope', '$http', '$stateParams', 'pendingExamCount', 'helpers', 'videoService', 'toastr', 'examTypes',
    function ($scope, $http, $stateParams, pendingExamCount, helpers, videoService, toastr, examTypes) {

        $scope.helpers = helpers;
        $scope.videoService = videoService.instance();
        $scope.currentExamId = $stateParams.examId;
        $scope.selectedExam = {};
        $scope.selectedVideo = null; 
        $scope.pastExams = {};
        $scope.pendingExamCount = pendingExamCount;
        $scope.errors = [];
        $scope.selectedEye = false;
        $scope.availableEyesVideos = [];
        $scope.examTypes = examTypes.get();
        $scope.personas = $scope.examTypes.DigitalRetinopathy.personas;
        $scope.previousExam = {};
        $scope.nextExam = {};
        $scope.previousDisabled = false;
        $scope.nextDisabled = false;
        $scope.cpt_codes = ['', '92227', '92228', '92250'];

        $scope.selectEye = function (whichEye) {
            if (!$scope.availableEyesVideos.includes(whichEye)) {
                console.error("Invalid eye to select: " + whichEye);
                $scope.selectFirstAvailableEye();
                return;
            }
            $scope.selectedEye = whichEye;
            if ($scope.selectedExam.videos.data.length > 0) {
                for (i = 0; i < $scope.selectedExam.videos.data.length; i++) {
                    if ($scope.selectedExam.videos.data[i].side == $scope.selectedEye) {
                        $scope.selectVideo($scope.selectedExam.videos.data[i], false);
                    }
                }
            }
        };

        $scope.selectVideo = function (video, autoplay) {
            $scope.selectedVideo = video;
            $scope.videoService.selectVideo(video, autoplay);
        };

        $scope.selectFirstAvailableEye = function () {
            if ($scope.availableEyesVideos.includes('left')) {
                $scope.selectEye('left');
                return;
            } else if ($scope.availableEyesVideos.includes('right')) {
                $scope.selectEye('right');
                return;
            }
        };

        $scope.$on("$destroy", function () {
            $scope.videoService.destroy()
            $("#left-nav").removeClass("in");
        });

        $scope.editable = function () {
            return $scope.selectedExam.status == 'Pending' || $scope.selectedExam.status == 'Saved'
        };

        $scope.setSelectedExam = function (exam) {
            $scope.selectedExam = exam;
            $scope.getPatientExams($scope.selectedExam.patient.data.id);
            $scope.selectedExam.images = [];
            $scope.selectedExam.patient.data.date_of_birth = helpers.formatDT($scope.selectedExam.patient.data.date_of_birth);
            for (i = 0; i < $scope.selectedExam.videos.data.length; i++) {
                const side = $scope.selectedExam.videos.data[i].side.toLowerCase();
                if (!$scope.availableEyesVideos.includes(side)) {
                    $scope.availableEyesVideos.push(side);
                }
                if ($scope.selectedExam.videos.data[i].images) {
                    for (var i1 = 0; i1 < $scope.selectedExam.videos.data[i].images.data.length; i1++) {
                        $scope.selectedExam.images.push($scope.selectedExam.videos.data[i].images.data[i1]);
                    }
                }
            }
            if (!$scope.selectedExam.cpt_code) {
                $scope.selectedExam.cpt_code = '';
            }
        };

        $scope.getExam = function (examId) {
            $http({
                method: 'POST',
                url: '/' + api_prefix + '/getExam/' + examId
            }).then(function (response) {
                $scope.setSelectedExam(response.data.data);
                $scope.videoService.init();
                // console.log($scope.selectedExam.videos.data);
                $scope.selectFirstAvailableEye();
                $scope.getPrevious();
                $scope.getNext();
            });
        };

        $scope.getPrevious = function () {
            $http({
                method: 'POST',
                url: '/getPreviousExam/',
                data: { "examId": $scope.selectedExam.id }
            }).then(function (response) {
                if (response.data) {
                    $scope.previousExam = response.data;
                    $scope.previousDisabled = false;
                } else {
                    $scope.previousDisabled = true;
                }
            })
        }

        $scope.getNext = function () {
            $http({
                method: 'POST',
                url: '/getNextExam/',
                data: { "examId": $scope.selectedExam.id }
            }).then(function (response) {
                if (response.data) {
                    $scope.nextExam = response.data;
                    $scope.nextDisabled = false;
                } else {
                    $scope.nextDisabled = true;
                }
            })
        }

        $scope.getPatientExams = function (patientId) {
            $http({
                method: 'POST',
                url: '/' + api_prefix + '/getUserExams',
                data: {
                    patient_id: patientId,
                    status: ['Pending', 'Saved', 'Reviewed', 'Opened']
                }
            }).then(function (response) {
                $scope.pastExams = response.data.data;
            });
        };

        $scope.submitExam = function (exam, status) {
            $http({
                method: 'POST',
                url: '/exam/submit',
                data: {
                    id: exam.id,
                    findings: exam.findings,
                    rationale: exam.rationale,
                    followup: exam.followup,
                    followed_up: exam.followed_up,
                    rationale_diabetes: exam.rationale_diabetes,
                    rationale_migraine: exam.rationale_migraine,
                    rationale_hypertension: exam.rationale_hypertension,
                    rationale_high_cholesterol: exam.rationale_high_cholesterol,
                    rationale_other: exam.rationale_other,
                    status: status,
                    urgent: exam.urgent,
                    images: [],
                    cpt_code: exam.cpt_code,
                }
            }).then(function (response) {
                $scope.errors = [];
                toastr.success('Exam has been ' + response.data.data.status);
                $scope.setSelectedExam(response.data.data);
            }, function (errorResponse) {
                $scope.errors = errorResponse.data.errors;
            });
        };

        $scope.goToTimestamp = function (video, timestamp) {
            if ($scope.selectedVideo.side != video.side) {
                $scope.selectEye(video.side);
            }
            $scope.videoService.goToTimestamp(video, timestamp);
        };

        $scope.goToTimestampByVideoId = function (videoId, timestamp) {
            var video = false;
            $scope.selectedExam.videos.data.some(function (vid) {
                if (vid.id == videoId) {
                    video = vid;
                    return true;
                }
            });
            if (!video) {
                throw new Error("Unable to find video with id " + videoId + " for current exam.");
            }
            $scope.goToTimestamp(video, timestamp);
        };
        $scope.getExam($scope.currentExamId);
    }]);