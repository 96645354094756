var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
var is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
var is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
var is_safari = navigator.userAgent.indexOf("Safari") > -1;
var is_opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
if ((is_chrome) && (is_safari)) { is_safari = false; }
if ((is_chrome) && (is_opera)) { is_chrome = false; }

angular.module('helpers', [])
    .factory('helpers', function () {
        return {
            findBy: function (source, value, searchBy) {
                searchBy = searchBy ? searchBy : 'id';
                for (var i in source) {
                    if (source[i][searchBy] === value) {
                        return source[i];
                    }
                }
            },

            findIdBy: function (source, value, searchBy) {
                searchBy = searchBy ? searchBy : 'id';
                for (var i in source) {
                    if (source[i][searchBy] === value) {
                        return i;
                    }
                }
            },

            formatDT: function (time, format) {
                format = format ? format : 'MM-DD-YYYY';
                return moment(time).format(format);
            },

            toDateObject: function (dateString) {
                var vObj = moment(dateString);
                if (!vObj.isValid()) {
                    console.warn("Invalid date time string " + dateString);
                    return '';
                }
                return vObj.toDate();
            },

            dateToDateString: function (date, format) {
                format = format ? format : 'YYYY-MM-DD HH:mm:ss';
                var dt = moment(date);
                if (!dt.isValid()) {
                    console.warn("Invalid date time string " + dateString);
                    return '';
                }
                return dt.format(format);
            },

            getSortingParams: function (sorting) {
                if (typeof sorting != 'undefined') {
                    var key = Object.keys(sorting)[0];
                    return {
                        by: key,
                        direction: sorting[key]
                    }
                }
            },

            scrollToTop: function () {
                $("html, body").animate({
                    scrollTop: 0
                }, 600);
            },

            capitalizeFirstLetter: function (string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },

            pad: function (n, width, z) {
                z = z || '0';
                n = n + '';
                return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
            },

            convertNumToTime(number) {
                if (number == 0) {
                    return "00:00";
                }
                minutes = Math.floor(number/60);
                seconds = number % 60;
                minutesString = (minutes < 10 ? "0" : "") + String(minutes);
                secondsString = (seconds < 10 ? "0" : "") + String(seconds);
                result = minutesString + ":" + secondsString;
                return result;
            }
        }
    });