PendingExam = function(Exams, currentUser) {
    var pending = this;
    pending.exams = Exams;
    pending.currentUser = currentUser;
    pending.count = null;

    pending.countChanged = function(){};
    pending.updatePendingCount = function () {
        if(currentUser.role != "Admin") {
            Exams.getPendingCount().then(function (result) {
                if(pending.count != result.data.count) {
                    pending.countChanged();
                }
                pending.count = (result.data.count);
                setTimeout(pending.updatePendingCount, 15000);
            });
        }
    };
};

var pendingExam;

angular.module('pendingExamCount', [])
    .factory('pendingExamCount', function(Exams, currentUser) {
        pendingExam = new PendingExam(Exams, currentUser);
        pendingExam.updatePendingCount();
        return pendingExam;
    });